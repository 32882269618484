/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #2ca8ff;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #1388db;
}

.main-panel {
    overflow-y: auto;
}

.now-ui-icons,
.table-icon {
    font-size: 18px !important;
}


#form-file-upload {
    height: 10rem;
    width: 25rem;
    max-width: 100%;
    text-align: center;
    position: relative;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    text-transform: lowercase;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    /* font-family: 'Oswald', sans-serif; */
    background-color: transparent;
    font-family: cursive;
    font-weight: 500;
    font-size: x-large;
}

.upload-button:hover {
    text-decoration-line: none;
}

#dropZone {
    justify-content: center;
    display: grid;
}

.tab-not-active {
    background-color: #d1cccb !important;
    color: rgb(255 255 255 / 80%) !important;
}

.upload-button-style {
    padding: 5px 15px 10px 20px;
    font-family: monospace;
    font-size: 16px;
    margin-left: 40px !important;
}

.upload-button-style-i {
    top: 6px !important;
    right: 5px !important;
    font-size: 25px !important;
}

body {
    color: #2c2c2c;
    font-size: 15px;
    font-family: monospace;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

input {
    font-family: monospace;
}

.disabledDiv {
    pointer-events: none;
    opacity: 0.4;
}


.section-heading {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 20px;
    color: #007BFF;
}

.user-instructions {
    margin-bottom: 20px;
}

.notification {
    color: #28A745;
    font-weight: bold;
}

.development-phase {
    color: #FFC107;
    font-weight: bold;
    margin-top: 20px;
}

.form-labels {
    color: #2196F3;
    font-size: larger;
    font-weight: 800;
}

/* Custom styles for the select tag */
.form-select {
    background: #258ed836;
    color: #2196F3;
    font-size: medium;
    font-weight: 800;
    border: 1px solid #2196F3;
    /* Add a border for better visibility */
    border-radius: 5px;
    /* Add some border-radius for a softer look */
    padding: 8px;
    /* Adjust padding for better spacing */
    outline: none;
    /* Remove the default outline */
    transition: border-color 0.2s ease-in-out;
    /* Add a transition for a smooth effect */
}

/* Hover effect for better user interaction */
.form-select:hover {
    border-color: #1565c0;
    /* Change border color on hover */
}

/* Focus effect for better accessibility */
.form-select:focus {
    border-color: #1565c0;
    /* Change border color on focus */
}

.form-comment {
    color: #0b0c0c;
    font-size: medium;
    font-weight: 200;
    font-family: sans-serif;
}

.error {
    display: inline-block !important;
    color: red;
}